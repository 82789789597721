.py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}


.w-full {
    width: 100%;
}

.flex {
    display: flex;
}

.w-1\/3 {
    width: 33.333333%;
}

.relative {
    position: relative;
}

.text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
}

.border-primary {
    border-color: #ffe804;
}

.border-2 {
    border-width: 2px;
}

.rounded-full {
    border-radius: 9999px;
}

.justify-center {
    justify-content: center;
}

.items-center {
    align-items: center;
}

.w-7 {
    width: 1.75rem !important;
}

.h-7 {
    height: 1.75rem !important;
}

.w-4 {
    width: 1rem !important;
}

.h-4 {
    height: 1rem !important;
}

.rounded-full {
    border-radius: 9999px !important;
}

.capitalize {
    text-transform: capitalize;
}

.text-xs {
    font-size: .75rem;
    line-height: 1rem;
}

.align-middle {
    vertical-align: middle;
}

.bg-accent {
    background-color: #fff;
}

.-translate-x-1\/2 {
    --tw-translate-x: -50%;
    transform: translate(var(--tw-translate-x),0) rotate(0) skew(0) skewY(0) scaleX(1) scaleY(1);
}

.absolute {
    position: absolute;
}

.content-center {
    align-content: center;
}

.flex-1 {
    flex: 1 1 0%;
}

.w-0 {
    width: 0px;
}

.h-1 {
    height: 0.25rem;
}

.bg-primary {
    background-color: #ffe804 !important;
}

.border-2 {
    border: 2px solid #ffe804 !important;
}

.max-w-3xl {
    max-width: 48rem;
}

.border-input{
    border: 1px solid #444444;
}

.flex-col {
    flex-direction: column;
}

.gap-3 {
    gap: 0.75rem;
}

.gap-2 {
    gap: 1.5rem;
}

.grid {
    display: grid;
}

.text-green-500 {
    --tw-text-opacity: 1;
    color: rgb(34 197 94 / var(--tw-text-opacity));
}

.bg-green-500\/20 {
    background-color: #22c55e33;
}

.rounded-xl {
    border-radius: 0.75rem;
}

article.notification.is-white {
    font-size: .88rem;
    margin-bottom: 0.63rem;
}


.media {
    align-items: flex-start;
    display: flex;
    text-align: left;
}

.notification {
    background-color: #f5f5f5;
    border-radius: 4px;
    padding: 14px;
    position: relative;
    word-break: break-all!important;
}

article.notification.is-white .media-left {
    margin-right: 1rem;
}

.media-left {
    margin-right: 1rem;
    flex-basis: auto;
    flex-grow: 0;
    flex-shrink: 0;
}

article.notification.is-white .media-content {
    font-size: .88rem;
    flex-basis: auto;
    flex-grow: 1;
    flex-shrink: 1;
    text-align: left;
}

article.notification.is-white .media-content a {
    text-decoration: none;
}

.media-right {
    margin-left: 1rem;
}


.has-text-success {
    color: #37d3a6!important;
}

.notification.is-success {
    background-color: #37d3a6;
    font-size: .88rem;
    color: #00174b!important;
}

a:hover{
    color: #c1c1c1;
}


.text-red-800 {
    --tw-text-opacity: 1;
    color: rgb(155 28 28/var(--tw-text-opacity));
  }
  
  .bg-red-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(253 232 232/var(--tw-bg-opacity));
  }
  
  
  .text-green-800 {
    --tw-text-opacity: 1;
    color: rgb(3 84 63/var(--tw-text-opacity)) !important;
  }
  
  .bg-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(222 247 236/var(--tw-bg-opacity)) !important;
  }
  
  .text-blue-800 {
    --tw-text-opacity: 1;
    color: rgb(30 66 159/var(--tw-text-opacity)) !important;
  }
  
  .bg-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(225 239 254/var(--tw-bg-opacity)) !important;
  }

@media (min-width: 1024px){
    .lg\:p-6 {
        padding: 1.5rem;
    }

    .grid-cols-2 {
        grid-template-columns: repeat(2,minmax(0,1fr));
    }
}

